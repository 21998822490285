import React from 'react';
import classnames from 'classnames';
import { NeoIcon } from '@web-apps/neo-icons';
import { Avatar } from '@panda/ui';
import NeoShapes from '@panda/ui/src/assets/illustrations/neo_shapes.svg';
import greenIcon from './static/green-12.svg';
import classes from './BlurredInterfaceBackground.scss';
import useTranslations from '../../hooks/translations';
import SipgateLogoWhite from './static/sipgate_wort-bild-marke_weiss.svg';
import useSignupData from '../../hooks/SignupData';

const BlurredInterfaceBackground = () => {
	const { translate } = useTranslations();
	const { signupData } = useSignupData();
	const { product } = signupData;
	const isNeoPbx = product === 'team_neopbx';

	return isNeoPbx ? (
		<div tabIndex={-1} className={classnames(classes.blurFixedWrapperNeoPbx)}>
			<nav className="neo-dark">
				<header>
					<div className={classnames(classes.hideButton)}>
						<NeoIcon
							name="Hide_navigation"
							variant="line"
							size={16}
							color="var(--neo-color-global-content-neutral-intense"
						/>
					</div>
				</header>

				<ul>
					<li className={classnames({ [classes.active]: true, [classes.selected]: true })}>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="House"
								variant="solid"
								size={24}
								color="var(--neo-color-global-content-neutral-intense"
							/>
							Startseite
						</div>
					</li>
					<li className={classnames(classes.active)}>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="Pbx"
								variant="line"
								size={24}
								color="var(--neo-color-global-content-neutral-intense"
							/>
							Telefonanlage
							<NeoIcon name="Expand" variant="solid" size={24} className={classes.expandActive} />
						</div>

						<ul>
							<li>User</li>
							<li>Rufnummern</li>
							<li>Standorte</li>
							<li>Mobilfunk</li>
							<li>Gruppen</li>
							<li>Channels</li>
							<li>VoIP-Zusatzanschlüsse</li>
							<li>Plug & Play</li>
							<li>Individualisierung</li>
						</ul>
					</li>
					<li>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="Products"
								variant="line"
								size={24}
								color="var(--neo-color-global-content-neutral-moderate)"
							/>
							Produkte
							<NeoIcon name="Expand" variant="solid" size={24} className={classes.expandInactive} />
						</div>
					</li>
					<li>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="Administration"
								variant="line"
								size={24}
								color="var(--neo-color-global-content-neutral-moderate)"
							/>
							Konto
							<NeoIcon name="Expand" variant="solid" size={24} className={classes.expandInactive} />
						</div>
					</li>
				</ul>
			</nav>
			<main>
				<header>
					<div className={classnames(classes.mobileNavButton)}>
						<span />
						<span />
						<span />
					</div>
					<img src={SipgateLogoWhite} alt="" className={classnames(classes.mobileLogo)} />
					<p>Startseite</p>
					<div className={classnames(classes.buttonWrapper)}>
						<div className={classnames(classes.mockButton)}>
							<img src={greenIcon} alt="" />
							sipgate Status
						</div>
						<div className={classnames(classes.mockButton)}>
							Meine Telefonie
							<NeoIcon
								name="ArrowRight"
								variant="line"
								size={24}
								color="var(--neo-color-web-app-component-area-switch-content-default)"
							/>
						</div>
						<Avatar firstname="S" lastname="G" />
					</div>
				</header>
				<div className={classnames(classes.content)}>
					<div className={classnames(classes.greeting)}>
						<Avatar firstname="S" lastname="G" size="biggest" />
						<div className={classnames(classes.greetingTextWrapper)}>
							<h2>
								Guten Tag
								<br />
								<em>Jobs at sipgate.de</em>
							</h2>
							<span>Kundennummer: 123456</span>
						</div>
					</div>
					<div className={classnames(classes.widgetWrapper)}>
						<div className={classnames(classes.widget)}>
							<header>
								<h2>
									User <span>[1]</span>
								</h2>
								<div className={classnames(classes.buttonWrapper)}>
									<div className={classnames(classes.mockButton)}>
										<NeoIcon
											name="Add"
											variant="line"
											size={24}
											color="var(--neo-color-global-content-primary-intense)"
										/>
									</div>
									<div className={classnames(classes.mockButton)}>
										Übersicht
										<NeoIcon
											name="ArrowRight"
											variant="line"
											size={24}
											color="var(--neo-color-global-content-primary-intense)"
										/>
									</div>
								</div>
							</header>
							<div className={classnames(classes.widgetContent)}>
								<div className={classnames(classes.userVisual)}>
									<svg viewBox="0, 0, 106, 106">
										<circle r="45" cx="50%" cy="50%" />
									</svg>
									<div>
										0<span>%</span>
									</div>
								</div>
								<div className={classnames(classes.userDescription)}>
									<h2>0 von 1 Usern nutzen die App</h2>
									<p>
										Davon nutzen 0 User bereits erweiterte App-Features, wie AI-Transkription und
										CRM-Integration.
									</p>
									<div className={classnames(classes.mockButton)}>App upgraden ✨</div>
								</div>
							</div>
							<footer className={classnames(classes.userFooter)}>
								<strong>Verteilung</strong>
								<div>
									<div>
										<span />
										sipgate App: 0
									</div>
									<div>
										<span />
										sipgate App Business: 0
									</div>
									<div>
										<span />
										sipgate App Premium: 0
									</div>
								</div>
							</footer>
						</div>
						<div className={classnames(classes.widget)}>
							<header>
								<h2>
									Channels <span>[0]</span>
								</h2>
								<div className={classnames(classes.buttonWrapper)}>
									<div className={classnames(classes.mockButton)}>
										<NeoIcon
											name="Add"
											variant="line"
											size={24}
											color="var(--neo-color-global-content-primary-intense)"
										/>
										Channel hinzufügen
									</div>
								</div>
							</header>
							<div className={classnames(classes.widgetContent)}>
								<div className={classnames(classes.channelVisual)}>
									<img alt="" src={NeoShapes} />
								</div>
								<div className={classnames(classes.channelDescription)}>
									<h2>Kein Channel hinzugefügt</h2>
									<p>
										Erstellen Sie Ihren ersten Channel, um die Zusammenarbeit Ihres Teams zu
										verbessern und Kundenanfragen effizienter zu bearbeiten.
									</p>
								</div>
							</div>
						</div>

						<div className={classnames(classes.widget)}>
							<header>
								<h2>Produktupdates</h2>
							</header>
							<div className={classnames(classes.widgetContent)}>
								<div className={classnames(classes.updateWrapper)}>
									<div className={classnames(classes.updateEntry)}>
										<p>02.04.2025</p>
										<strong>
											Neuer Name, gleiche App: Aus der sipgate App CLINQ wird die sipgate App.
										</strong>
									</div>
									<div className={classnames(classes.updateEntry)}>
										<p>20.03.2025</p>
										<strong>Channels im Tarif Business S nutzen</strong>
									</div>
									<div className={classnames(classes.updateEntry)}>
										<p>01.03.2025</p>
										<strong>
											Die Pipedrive Integration kann jetzt Notizen und Aufgaben ins CRM loggen.
										</strong>
									</div>
									<div className={classnames(classes.updateEntry)}>
										<p>01.03.2025</p>
										<strong>0900-0 und 0900-1 jetzt über per VoIP erreichbar</strong>
									</div>
									<div className={classnames(classes.updateEntry)}>
										<p>27.01.2025</p>
										<strong>Ein- / Ausloggen von einzelnen Usern in Channels</strong>
									</div>
									<div className={classnames(classes.updateFadeout)} />
								</div>
							</div>
							<footer className={classnames(classes.updateFooter)}>
								<p>Alle Produktupdates</p>
								<NeoIcon
									name="External_link"
									variant="line"
									size={16}
									color="var(--neo-color-global-content-neutral-intense)"
								/>
							</footer>
						</div>
					</div>
					<div className={classnames(classes.accountManagementOverview)}>
						<section>
							<h2>Quick Links</h2>
							<div className={classnames(classes.tileOuterWrapper)}>
								<div className={classnames(classes.tileWrapper)}>
									<div className={classnames(classes.tile)}>
										<NeoIcon
											name="Phone_number"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
										<h3>Rufnummern buchen</h3>
									</div>
									<div className={classnames(classes.tile)}>
										<NeoIcon
											name="Location"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
										<h3>Standort hinzufügen</h3>
									</div>
									<div className={classnames(classes.tile)}>
										<NeoIcon
											name="Device_pool"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
										<h3>Mobilfunk buchen</h3>
									</div>
									<div className={classnames(classes.tile)}>
										<NeoIcon
											name="Ivr"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
										<h3>IVR hinzufügen</h3>
									</div>
									<div className={classnames(classes.tile)}>
										<NeoIcon
											name="Invoice"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
										<h3>Rechnungen einsehen</h3>
									</div>
									<div className={classnames(classes.tile)}>
										<NeoIcon
											name="Fax"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
										<h3>Faxanschluss hinzufügen</h3>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</main>
		</div>
	) : (
		<div tabIndex={-1} className={classnames(classes.blurFixedWrapper)}>
			<nav className="neo-dark">
				<header>
					<div className={classnames(classes.hideButton)}>
						<NeoIcon
							name="Hide_navigation"
							variant="line"
							size={16}
							color="var(--neo-color-global-content-neutral-intense"
						/>
					</div>
				</header>

				<ul>
					<li className={classnames({ [classes.active]: true, [classes.selected]: true })}>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="House"
								variant="solid"
								size={24}
								color="var(--neo-color-global-content-neutral-intense"
							/>
							Startseite
						</div>
					</li>
					<li className={classnames(classes.active)}>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="Pbx"
								variant="line"
								size={24}
								color="var(--neo-color-global-content-neutral-intense"
							/>
							Telefonanlage
							<NeoIcon name="Expand" variant="solid" size={24} className={classes.expandActive} />
						</div>

						<ul>
							<li>User</li>
							<li>Rufnummern</li>
							<li>Standorte</li>
							<li>Mobilfunk</li>
							<li>Gruppen</li>
							<li>Channels</li>
							<li>VoIP-Zusatzanschlüsse</li>
							<li>Plug & Play</li>
							<li>Individualisierung</li>
						</ul>
					</li>
					<li>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="Products"
								variant="line"
								size={24}
								color="var(--neo-color-global-content-neutral-moderate)"
							/>
							Produkte
							<NeoIcon name="Expand" variant="solid" size={24} className={classes.expandInactive} />
						</div>
					</li>
					<li>
						<div className={classnames(classes.linkWrapper)}>
							<NeoIcon
								name="Administration"
								variant="line"
								size={24}
								color="var(--neo-color-global-content-neutral-moderate)"
							/>
							Konto
							<NeoIcon name="Expand" variant="solid" size={24} className={classes.expandInactive} />
						</div>
					</li>
				</ul>
			</nav>
			<main>
				<header>
					<div className={classnames(classes.mobileNavButton)}>
						<span />
						<span />
						<span />
					</div>
					<img src={SipgateLogoWhite} alt="" className={classnames(classes.mobileLogo)} />
					<p>Startseite</p>
					<div className={classnames(classes.buttonWrapper)}>
						<div className={classnames(classes.mockButton)}>
							<img src={greenIcon} alt="" />
							sipgate Status
						</div>
						<div className={classnames(classes.mockButton)}>
							Meine Telefonie
							<NeoIcon
								name="ArrowRight"
								variant="line"
								size={24}
								color="var(--neo-color-web-app-component-area-switch-content-default)"
							/>
						</div>
						<Avatar firstname="S" lastname="G" />
					</div>
				</header>
				<div className={classnames(classes.content)}>
					<div className={classnames(classes.greeting)}>
						<Avatar firstname="S" lastname="G" size="biggest" />
						<div className={classnames(classes.greetingTextWrapper)}>
							<h2>
								Guten Tag
								<br />
								<em>Jobs at sipgate.de</em>
							</h2>
							<span>Kundennummer: 123456</span>
						</div>
					</div>
					<div className={classnames(classes.accountManagementOverview)}>
						<section>
							<h2>Telefonanlage</h2>
							<div className={classnames(classes.tileWrapper)}>
								<div className={classnames(classes.tile)}>
									<h3>{translate('USER_ADMINISTRATION')}</h3>
									<div>
										<NeoIcon
											name="User"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('USER_ADMINISTRATION_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_PHONENUMBERS')}</h3>
									<div>
										<NeoIcon
											name="Number"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_NUMBERS_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_LOCATIONS')}</h3>
									<div>
										<NeoIcon
											name="Location"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_LOCATIONS_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_MOBILE_TELEPHONY_TITLE')}</h3>
									<div>
										<NeoIcon
											name="Device_pool"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_MOBILE_TELEPHONY_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('GROUP_ADMINISTRATION')}</h3>
									<div>
										<NeoIcon
											name="Group"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('GROUP_ADMINISTRATION_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('CHANNEL_ADMINISTRATION')}</h3>
									<div>
										<NeoIcon
											name="Multi_user_channel"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('CHANNEL_ADMINISTRATION_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('PUBLIC_PHONES_ADMINISTRATION')}</h3>
									<div>
										<NeoIcon
											name="Voip_phone"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('PUBLIC_PHONES_ADMINISTRATION_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_PHONES')}</h3>
									<div>
										<NeoIcon
											name="Plug_and_play"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_PHONES_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_TELEPHONY_SETTINGS_TITLE')}</h3>
									<div>
										<NeoIcon
											name="Customization"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_TELEPHONY_SETTINGS_DESCRIPTION')}</p>
								</div>
							</div>
						</section>

						<section>
							<h2>Produkte</h2>
							<div className={classnames(classes.tileWrapper)}>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_TRUNKS')}</h3>
									<div>
										<NeoIcon
											name="Trunk"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_TRUNKS_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_CONFERENCEROOM')}</h3>
									<div>
										<NeoIcon
											name="Conference_room"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_CONFERENCEROOM_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('IVR_ADMINISTRATION')}</h3>
									<div>
										<NeoIcon
											name="Ivr"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('IVR_ADMINISTRATION_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('WEBHOOKS_LINK')}</h3>
									<div>
										<NeoIcon
											name="Sipgate_io"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('WEBHOOKS_LINK_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('API_CLIENTS_LINK')}</h3>
									<div>
										<NeoIcon
											name="Api_clients"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('API_CLIENTS_LINK_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_INTEGRATIONS_TITLE')}</h3>
									<div>
										<NeoIcon
											name="Integrations"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_INTEGRATIONS_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_MS_TEAMS_TITLE')}</h3>
									<div>
										<NeoIcon
											name="Ms_teams"
											variant="solid"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_MS_TEAMS_DESCRIPTION')}</p>
								</div>
							</div>
						</section>

						<section>
							<h2>Konto</h2>
							<div className={classnames(classes.tileWrapper)}>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_ACCOUNT')}</h3>
									<div>
										<NeoIcon
											name="Administration"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_ACCOUNT_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_SAML_SSO')}</h3>
									<div>
										<NeoIcon
											name="Sso"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_SAML_SSO_DESCRIPTION')}</p>
								</div>
								<div className={classnames(classes.tile)}>
									<h3>{translate('ACCOUNT_NAVIGATION_AFFILIATE')}</h3>
									<div>
										<NeoIcon
											name="Partner_program"
											variant="line"
											size={32}
											color="var(--neo-color-global-content-neutral-intense)"
										/>
									</div>
									<p>{translate('ACCOUNT_NAVIGATION_AFFILIATE_DESCRIPTION')}</p>
								</div>
							</div>
						</section>
					</div>
				</div>
			</main>
		</div>
	);
};

export default BlurredInterfaceBackground;
