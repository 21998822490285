import React from 'react';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import classes from './Layout.scss';
import SipgateLogo from '../../media/images/sipgate_logo.svg';
import SipgateLogoWhite from '../../media/images/sipgate_wort-bild-marke_weiss.svg';
import useTranslations from '../../hooks/translations';
import useSignupData from '../../hooks/SignupData';

type Props = {
	children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
	const { isDark } = useTheme(false);
	const { translate } = useTranslations();

	const { signupData } = useSignupData();
	const { country } = signupData;

	const isUk = country === 'GB';

	return (
		<div className={classes.wrapper}>
			{isUk ? (
				<img
					className={classnames(classes.sipgateLogoWhite, isDark && classes.dark)}
					src={SipgateLogoWhite}
					alt={translate('SIPGATE_LOGO_ALT')}
					aria-hidden="true"
				/>
			) : (
				<img
					className={classnames(classes.sipgateLogo, isDark && classes.dark)}
					src={SipgateLogo}
					alt={translate('SIPGATE_LOGO_ALT')}
					aria-hidden="true"
				/>
			)}
			<main className={classes.content}>{children}</main>
		</div>
	);
};

export default Layout;
